import * as d3 from "d3";
import { createIntroCard } from "./intro-card";
import { createStarryNight } from "./starry-night";

// Make sure that d3 libraries don’t get excluded from the optimized build
d3;

const introCardContainer = document.getElementById("intro-card-container");
const introCard = createIntroCard({ firstName: "Sacha" }).node();

if (introCardContainer && introCard) {
  introCardContainer.appendChild(introCard);
}

const backgroundContainer = document.getElementById("background-container");
const background = createStarryNight({}).node();

if (backgroundContainer && background) {
  backgroundContainer.appendChild(background);
}
