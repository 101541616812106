import * as d3 from "d3";
import type { Component } from "../component";
import { createCard } from "../card";

require("./style.css");

export type IntroCard = {
  firstName: string;
};

export const createIntroCard: Component<IntroCard> = (card, previous) => {
  const selection = (previous ? d3.select(previous) : createEmptyIntroCard(card))
    .datum(card);

  selection
    .select(".card__title.intro-card__title")
    .text("La Constellation de Saskar");

  selection
    .select(".intro-card__paragraphs")
    .selectAll(".intro-card__paragraph")
    .data([
      `Ce site présente la famille de Sacha et Oskar. Afin de respecter la vie privée de ses membres, l’accès est protégé par un mot de passe qui vous a normalement été confié.`,
      `Nous avons fait de notre mieux pour retrouver les noms, photos, dates de naissance ou de décès de chacun, mais bien sûr il y aura des erreurs et des oublis. Aidez nous à corriger cette constellation généalogique en <a class="intro-card__email-link" href="mailto:famille@rbelouin.com">nous contactant</a>!`,
    ])
    .join("p")
    .classed("intro-card__paragraph", true)
    .html(d => d);

  return selection;
};

function createEmptyIntroCard(card: IntroCard) {
  const selection = createCard(card)
    .classed("intro-card", true);

  selection
    .append("h1")
    .classed("card__title intro-card__title", true);

  selection
    .append("div")
    .classed("intro-card__paragraphs", true)
    .append("p")
    .classed("intro-card__paragraph", true);

  const form = selection
    .append("form")
    .classed("intro-card__log-in-form", true);

  form
    .append("label")
    .attr("for", "password")
    .classed("intro-card__log-in-label", true)
    .text("Mot de passe :");

  const password = form
    .append("input")
    .attr("type", "password")
    .attr("name", "password")
    .attr("id", "password")
    .classed("intro-card__log-in-password", true);

  const error = form
    .append("div")
    .classed("intro-card__log-in-error", true);

  const button = form
    .append("button")
    .attr("type", "submit")
    .classed("intro-card__log-in-button", true)
    .text("Se connecter");

  form.on("submit", (e) => {
    e.preventDefault();
    sendPassword(password, button, error)
  });

  return selection;
}

function sendPassword(
  password: d3.Selection<HTMLInputElement, IntroCard, null, undefined>,
  button: d3.Selection<HTMLButtonElement, IntroCard, null, undefined>,
  error: d3.Selection<HTMLDivElement, IntroCard, null, undefined>,
) {
  button
    .attr("disabled", true)
    .classed("intro-card--loading", true);

  const artificialDelay = new Promise(resolve => {
    setTimeout(resolve, 1500);
  });

  fetch("/login", {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      password: password.property("value"),
    }),
  }).then(res => {
    if (res.ok) {
      window.location.href = "/";
    } else {
      artificialDelay.then(() => {
        password
          .property("value", "");

        button
          .attr("disabled", null)
          .classed("intro-card--loading", false);

        error
          .classed("intro-card--visible", true)
          .html(`Le mot de passe est invalide. <a class="intro-card__email-link" href="mailto:famille@rbelouin.com">Contactez nous</a> pour récupérer celui qui est correct.`);
      });
    }
  });
}

